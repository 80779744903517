
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ru"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.yaml", load: () => import("../lang/en.yaml" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_en_yaml" */), cache: true }],
  "ru": [{ key: "../lang/ru-RU.yaml", load: () => import("../lang/ru-RU.yaml" /* webpackChunkName: "locale__usr_src_nuxt_app_lang_ru_RU_yaml" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../nuxt-i18n.js?hash=82975b67&config=1" /* webpackChunkName: "__nuxt_i18n_js_82975b67" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./nuxt-i18n.js",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "/usr/src/nuxt-app/lang/en.yaml"
      ]
    },
    {
      "code": "ru",
      "name": "Русский",
      "files": [
        "/usr/src/nuxt-app/lang/ru-RU.yaml"
      ]
    }
  ],
  "defaultLocale": "ru",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://local.getjob.cz/",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "/usr/src/nuxt-app/lang/en.yaml"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Русский",
    "files": [
      {
        "path": "/usr/src/nuxt-app/lang/ru-RU.yaml"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
