import {useAppToast} from '~/stores/appToast';

export interface Location {
  id: string;
  name: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

export const useLocationStore = defineStore('locationStore', () => {
  const {showApiErrorToast} = useAppToast();

  const currentLocation = ref<Location>();

  let locationsList: Location[] = [];

  function getLocationList(): Location[] {
    return locationsList;
  }

  function setCurrentLocation(location: Location) {
    currentLocation.value = location;
  }

  function setLocationsList(locations: Location[]) {
    locationsList = locations;
  }

  function fetchGeneralLocation() {
    return useGetJobApi('/general/locations', {
      method: 'GET',
      onResponseError: showApiErrorToast,
    });
  }

  function $reset() {
    currentLocation.value = undefined;
  }

  return {
    currentLocation,
    getLocationList,
    fetchGeneralLocation,
    setCurrentLocation,
    setLocationsList,
    $reset,
  };
});
