import type {FetchError} from 'ofetch';
import {useToast as usePrimeVueToast} from 'primevue/usetoast';
import type {GetJobApiError} from '~/types/get-job-api.interface';

export const useAppToast = defineStore('useToastStore', () => {
  const toast = usePrimeVueToast();

  function showSuccessToast(message: string) {
    toast.add({
      severity: 'success',
      detail: message,
      life: 3000,
    });
  }

  function showErrorToast(message: string) {
    toast.add({
      severity: 'warn',
      detail: message,
      life: 4000,
    });
  }

  function showApiErrorToast({response}: FetchError<GetJobApiError>) {
    console.warn(response);

    const errorMessage =
      (response?._data?.items && response?._data?.items[0]?.humanReadable) ||
      response?.statusText ||
      'Something went wrong';

    showErrorToast(errorMessage);
  }

  function $reset() {}

  return {
    showErrorToast,
    showSuccessToast,
    showApiErrorToast,
    $reset,
  };
});
